$(function() {
    sessionPlans();
});

function sessionPlans()
{
    if ($("#session-plan")) {
        $(".tab-navigation li:first-child").addClass('active');
        $(".tab-content div.tab-desktop-content:first-child").addClass('active');

        $(".tab-navigation li a").on('click', function(e) {

            var anchorPoint = $(this).attr('href');

            $(".tab-navigation li").removeClass('active');
            $(this).parent().addClass('active');

            $(".tab-desktop-content").removeClass('active');
            $(".tab-desktop-content" + anchorPoint).addClass('active');
            
            resizePanels();

            e.preventDefault();
            return false;
        });
        
        setTimeout(function() {
            resizePanels();
        }, 500);

        //does a video need loading in
        var videoBox = $("#session-video");

        if (videoBox.data('video')) {
            var options = {
                id: videoBox.data('video'),
                width: parseInt(videoBox.outerWidth()),
                loop: true
            };

            var player = new Vimeo.Player('session-video', options);
            player.setVolume(0);
        }
    }
}

function resizePanels()
{
    // only do this if we're above 992px
    var screenWidth = $(window).outerWidth();
    
    if (screenWidth >= 992) {
        var leftColHeight = $("#session-plan .border-right").outerHeight();
        var rightColHeight = $("#session-plan .border-left").outerHeight();

        if (leftColHeight > rightColHeight) {
            $("#session-plan .border-right, #session-plan .border-left").css('minHeight', leftColHeight + 'px');
        } else if (rightColHeight > leftColHeight) {
            $("#session-plan .border-right, #session-plan .border-left").css('minHeight', rightColHeight + 'px');
        }
        
        var videoHeight = parseInt($("#session-video").outerHeight());
        var panelHeight = parseInt($("#session-plan .border-left").outerHeight());

        var navHeight = panelHeight - videoHeight;
        $("#session-nav").css('minHeight', navHeight + 'px');
    } else {
        $("#session-plan .border-right, #session-plan .border-left, #session-nav").css('height', 'auto');
    }
}

var myEfficientFn = debounce(function() {
    resizePanels();
}, 250);

window.addEventListener('resize', myEfficientFn);

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};